import { Feature } from 'app/enums/Feature'
import { featureEnabled } from 'app/functions/feature'
import { useActiveWeb3React } from 'app/services/web3'
import { useUserNevellusGuard } from 'app/state/user/hooks'
import { useMemo } from 'react'

import useWalletSupportsNevellusGuard from './useWalletSupportsNevellusGuard'

export default function useNevellusGuardFeature() {
  const { chainId } = useActiveWeb3React()
  const [userEnabledNevellusGuard] = useUserNevellusGuard()
  const walletSupportsNevellusGuard = useWalletSupportsNevellusGuard()

  return useMemo(() => {
    return featureEnabled(Feature.NEVELLUSGUARD, chainId ?? -1) && walletSupportsNevellusGuard && userEnabledNevellusGuard
  }, [chainId, userEnabledNevellusGuard, walletSupportsNevellusGuard])
}
